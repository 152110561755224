import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import ImgModal from './ImgModal'
import loadingGif from './loading2.gif';
import backButton from './backButton.png';
import ReactGA from 'react-ga';

function createPageLinkFromId(id){
    return "https://www.facebook.com/"+id
}
const styles = theme => ({
  appBar: {
    position: 'relative',
  },
  icon: {
    marginRight: theme.spacing.unit * 2,
  },
  heroUnit: {
    backgroundColor: theme.palette.background.paper,
    //backgroundColor: "#282c34"
  },
  heroContent: {
    maxWidth: 600,
    margin: '0 auto',
    padding: `${theme.spacing.unit * 8}px 0 ${theme.spacing.unit * 6}px`,
  },
  heroButtons: {
    marginTop: theme.spacing.unit * 4,
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(1100 + theme.spacing.unit * 3 * 2)]: {
      width: 1100,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  cardGrid: {
    padding: `${theme.spacing.unit * 5}px 0`,
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '66.66%', // 3:2
    backgroundPosition: "center 15%",
  },
  cardContent: {
    flexGrow: 1,
    paddingBottom: "0px"
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing.unit * 6,
  },
  buttonLeft:{
      marginRight: "auto"
  },
  buttonRight:{
      marginLeft: "auto"
  }
});
function timestampToDate(timestamp){
    var d = new Date();
    d.setTime(timestamp*1000);
    return d.toISOString().substring(0,10);
}
class AlbumDisplay extends React.Component{
    constructor(props){
        super(props);
        this.state ={
            dataImg: [],
            showMore: false,
        }
    }

    createGridImgFromData(classes, elem, showImg){
        return (
            <Grid style={showImg?{}:{display:"None"}}item key={elem.imageLocation} xs={12} sm={6} md={6} lg={6}>
                <Card className={classes.card}>
                <CardMedia
                    onClick={this.onMediaClick}
                    className={classes.cardMedia}
                    image={elem.imageLocation}
                    title={elem.albumName}
                />
                <CardContent className={classes.cardContent}>
                    <Typography>
                        {timestampToDate(elem.timestamp)}
                    </Typography>
                    <Typography gutterBottom variant="h6" component="h6">
                        {elem.albumName}
                    </Typography>
                </CardContent>
                <CardActions>
                    <ImgModal imgSrc={elem.imageLocation}/>
                    <Button href={elem.imgUrl} onClick={
                    () => ReactGA.event({
                        category: 'SeeOriginal',
                        action: 'See original photo'
                    })} target="_blank" size="small" color="primary">
                        View Original
                    </Button>
                </CardActions>
                </Card>
            </Grid>
        )
    }

    refreshShowedImg(props){
        const { classes, data } = props;
        const limitTolerance = 0.44;
        if(data){
            data.sort((dataDicta, dataDictb) =>{
                return dataDicta['distance'] - dataDictb['distance']
            });
            const prettySureImg = data.filter(elem => {
                return elem['distance'] < limitTolerance
            }).map(elem=>this.createGridImgFromData(classes, elem, true));
            const restOfImgs = data.filter(elem => {
                return elem['distance'] >= limitTolerance
            }).map(elem=>this.createGridImgFromData(classes, elem, this.state.showMore));
            var dataImg = prettySureImg.concat(restOfImgs)
            if(!this.state.showMore && restOfImgs.length > 0) {
                dataImg.push(
                <Grid item key="See more button grid" xs={12} sm={12} md={12} lg={12}>
                <Button id="See more" onClick={()=>
                    {this.setState({showMore:true});
                        ReactGA.event({
                            category: 'Search',
                            action: 'See more'
                        });}
                    } color="primary" >
                    Show less accurate ones
                </Button>
                </Grid>
            )
            }
            this.setState({dataImg: dataImg})
        }
    }

    componentDidUpdate(prevProps,prevState){
        if (this.state.showMore !== prevState.showMore){
            this.refreshShowedImg(prevProps);
        }
    }
    
    componentWillReceiveProps(props){
        this.setState({showMore:false});
        this.refreshShowedImg(props);        
    }

    render() {
    const { classes, loading, pageInfo, back, newSearch} = this.props;
    //console.log("Album", data)
    function clearToDefault(){
        newSearch();
        ReactGA.event({
            category: 'Clear',
            action: 'New Search'
        });
    }
    return (
        <React.Fragment>
        <CssBaseline />
        <AppBar position="static" className={classes.appBar}>
            <Toolbar>
                <Button onClick={back} variant="outlined" color="primary" aria-label="Add" className={classes.buttonLeft}>
                    <img style={{width:"24px"}} src={backButton} alt="Back" />
                </Button>
                <Button onClick={clearToDefault} variant="contained" color="default" aria-label="Add" className={classes.buttonRight}>
                    New Search
                </Button>
            </Toolbar>
        </AppBar>
        <main>
            {/* Hero unit */
             pageInfo.name ? 
             <div className={classes.heroUnit}>
             <div className={classes.heroContent}>
                 <Typography component="h1" variant="h4" align="center" color="textPrimary" gutterBottom>
                     {pageInfo.name}
                 </Typography>
                 {pageInfo.message ?
                 <Typography component="p" align="center" color="textSecondary" paragraph>
                     {pageInfo.message}
                 </Typography>
                 :""}
                 <div className={classes.heroButtons}>
                 <Grid container spacing={16} justify="center">
                     { pageInfo.url ?     
                     <Grid item>
                     <Button variant="contained" href={pageInfo.url} target="_blank" color="primary">
                         Site Web
                     </Button>
                     </Grid>
                     : ""}
                     { pageInfo.id ?     
                     <Grid item>
                     <Button href={createPageLinkFromId(pageInfo.id)} target="_blank" variant="outlined" color="primary">
                         Facebook Page
                     </Button>
                     </Grid>
                     : ""}
                 </Grid>
                 </div>
             </div>
             </div>
             : ""}
            
            { loading ? 
                <img src={loadingGif} style={{margin: "auto", display: "block"}} alt="loading" />
                : 
                <div className={classNames(classes.layout, classes.cardGrid)}>
                {/* End hero unit */}
                {this.state.dataImg.length > 0 ? 
                <Grid container spacing={24}>
                    {this.state.dataImg}
                </Grid>
                : 
                <Typography component="h4" variant="h5" align="center" color="textPrimary" gutterBottom>
                    No photo found, try another album
                </Typography>}
                </div>
            }
            
        </main>
        {/* Footer 
        <footer className={classes.footer}>
            <Typography variant="h6" align="center" gutterBottom>
            Footer
            </Typography>
            <Typography variant="subtitle1" align="center" color="textSecondary" component="p">
            Something here to give the footer a purpose!
            </Typography>
        </footer>
         End footer */}
        </React.Fragment>
    );
    }
}

AlbumDisplay.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AlbumDisplay);