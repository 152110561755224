import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SwipeableViews from 'react-swipeable-views';
import AlbumDisplay from "./AlbumDisplay"
import ImageDropCrop from "./ImageDropCrop"
import axios from 'axios';
import AlbumChoice from './AlbumChoice';
import oktoImg from './Oktoberfest.jpg';
import commiImg from './commi.png';
import giImg from './giphoto.jpg';
import ReactGA from 'react-ga';

ReactGA.initialize('UA-128167255-1');

const styles = {
  tabs: {
    background: '#fff',
  },
  slide: {
    padding: "0px",
    minHeight: "1000px",
    color: '#fff',
    backgroundColor: '#282c34'
  },
  slide1: {
    //backgroundColor: '#FEA900',
    backgroundColor: '#006400',
    paddingTop: "30px",
  },
  slide2: {
    backgroundColor: '#B3DC4A',
    //backgroundColor: '#282c34'
  },
  slide3: {
    backgroundColor: '#FFFFFF',
  },
};

const choices = {
    "okto2018":{
      value:"okto2018",
      name:"Oktoberfest 2018",
      id: "oktoberfest.sherbrooke",
      imgUrl: oktoImg,
      message: "Le plus gros party universitaire thématique au Canada!"
    },
    "oktoberfestsherb":{
    value:"oktoberfestsherb",
    name:"Oktoberfest 2012-2017",
    id: "oktoberfest.sherbrooke",
    imgUrl: oktoImg,
    message: "Le plus gros party universitaire thématique au Canada!"
    }
}
/*
function downloadBase64File(base64Data, filename) {
  var element = document.createElement('a');
  element.setAttribute('href', base64Data);
  element.setAttribute('download', filename);
  element.style.display = 'none';
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
}*/

function base64StringtoFile(base64String, filename) {
  var arr = base64String.split(','), mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
  while(n--){
      u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, {type:mime});
}

function extractImageFileExtensionFromBase64(base64Data){
  return base64Data.substring("data:image/".length, base64Data.indexOf(";base64"))
}

export function image64toCanvasRef(canvasRef, image64, pixelCrop){
  const canvas = canvasRef // document.createElement('canvas');
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;
  const ctx = canvas.getContext('2d');
  const image = new Image()
  image.src = image64
  image.onload = function() {
      ctx.drawImage(
        image,
        pixelCrop.x,
        pixelCrop.y,
        pixelCrop.width,
        pixelCrop.height,
        0,
        0,
        pixelCrop.width,
        pixelCrop.height
      )
    }
}

class App extends React.Component {
  constructor(){
    super();
    this.imagePreviewCanevasRef = React.createRef();
    this.state = {
      index: 0,
      imgSrc : null,
      crop:{
        x: 20,
        y: 20,
        width: 20,
        height: 15
      },
      data : [],
      imgData: [],
      loading: false,
      pagename: null,
      buttonSelected: undefined,
      pageInfo: {},
      selected: null
    }
  }

  handleChange = (event, value) => {
    window.scrollTo(0, 0)
    this.setState({
      index: value,
    });
  };

  handleChangeIndex = index => {
    window.scrollTo(0, 0)
    this.setState({
      index,
    });
  };

  handleOnCropChange = (crop)=>{
    //console.log(crop)
    this.setState({crop:crop});
    //console.log(this.state.crop);
  }

  handleDrop = (files, rejectedFiles)=>{
    //console.log("accpt", files)
    //console.log("Reject", rejectedFiles)
    if(files && files.length !== 0){
      //alert(JSON.stringify(files));
      const currentFile = files[0];
      //console.log(currentFile);
      const reader = new FileReader();
      reader.addEventListener("load", ()=>{
        //console.log(reader.result);
        this.setState({
          imgSrc: reader.result
        });
      }, false);
      reader.readAsDataURL(currentFile);
    }
    ReactGA.event({
      category: 'PutPhoto',
      action: 'Drop photo'
    });
  }

  handleOnCropComplete = (crop, pixelCrop) =>{
    console.log(crop, pixelCrop);
    const canvasRef = this.imagePreviewCanevasRef.current;
    console.log(canvasRef)
    const {imgSrc} = this.state;
    image64toCanvasRef(canvasRef, imgSrc, pixelCrop);
  }

  handleClearToDefault = event =>{
    if (event) event.preventDefault()
    const canvas = this.imagePreviewCanevasRef.current
    if (canvas){
      const ctx = canvas.getContext('2d')
      ctx.clearRect(0,0,canvas.width,canvas.height)
    }
    this.setState({
      index:0,
      buttonSelected: undefined,
      imgSrc : null,
      crop:{
        x: 20,
        y: 20,
        width: 20,
        height: 15
      },
      data: [],
      imgData: [],
      pageInfo: {},
      selected: null
    })
  }
  sendImageData = (file, filename, pagename=null) =>{
    //const reqURL = "http://192.168.5.128:5001/"
    if (pagename == null){
      pagename = this.state.pagename;
    }
    const reqURL = "https://api.urbanrater.com:5001/?page="+pagename
    var formData = new FormData()
    formData.append("file", file, filename);
    //formData.append("page", "test");
    this.setState({loading: true})
    axios.post(reqURL , formData, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'accept': 'application/json',
        'Accept-Language': 'en-US,en;q=0.8',
        'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
      }
    })
    .then((response) => {
      //const imgData = this.createImageFromData(response.data)
      console.log(response);
      this.setState({data:response.data.matchs, loading:false})
    }).catch((error) => {
      //handle error
      this.setState({loading:false})
    });
    
    // download
    //downloadBase64File(imageData64, myFileName);
  }
  backToPage1 = () =>{
    window.scrollTo(0, 0)
    this.setState({index:0})
    ReactGA.event({
      category: 'Back',
      action: 'Back to image cut'
    });
  }
  backToPage2 = () =>{
    window.scrollTo(0, 0)
    this.setState({imgData: []})
    this.setState({index:1})
    ReactGA.event({
      category: 'Back',
      action: 'Back to album selection'
    });
  }
  /*reateImageFromData = data =>{
    if (data){
      const matchData = data['matchs'];
      //console.log(data)
      //console.log(matchData)
      matchData.sort((dataDicta, dataDictb) =>{
        return dataDicta['distance'] - dataDictb['distance']
      });
      console.log(matchData)
      return matchData.map(dataDict =>{
        const imgNameSplit = dataDict['imageLocation'].split("/")
        const imgName = imgNameSplit[imgNameSplit.length-1].split(".")[0]
        
        return <div>
          <a href={dataDict['imgUrl']} id={imgName} target="_blank"><img style={{maxWidth:"100%", maxHeight:"80%"}} src={dataDict['imageLocation']} alt="resultimg"/></a>
          <br/>
          </div>
      });
    }
    else return 
  }*/

  handleButtonClick = (event) =>{
    event.preventDefault();
    
    const { file, filename } = this.cropImage()
    this.sendImageData(file, filename)
    /*base64Img.requestBase64("", 
    (err, res, body)=>{
      if (res){
        console.log(res);
      }
    })*/
  }

  cropImage = ()=>{
    const canvasRef = this.imagePreviewCanevasRef.current;
    const {imgSrc} = this.state;
    const fileExtension = extractImageFileExtensionFromBase64(imgSrc);
    const imageData64 = canvasRef.toDataURL('image/' + fileExtension)
    const myFileName = "preview." + fileExtension;

    // file to be uploaded
    const myNewCroppedFile = base64StringtoFile(imageData64, myFileName);
    console.log(myNewCroppedFile)
    return {file: myNewCroppedFile, filename:myFileName}
  }

  onRadioButtonChange = (value, event)=>{
    //console.log(value)
    this.setState({buttonSelected: value, pagename: value})
    ReactGA.pageview("/Album");
    ReactGA.event({
      category: 'Search',
      action: 'Search in album '+value,
      label: value
    });
    if (this.imagePreviewCanevasRef.current){
      const { file, filename } = this.cropImage()
      this.sendImageData(file, filename, value)
      this.setState({index: 2, selected: value, pageInfo:choices[value]})
      window.scrollTo(0, 0)
    }
  }
  componentDidMount(){
    window.scrollTo(0, 0);
    ReactGA.pageview("/");
  }
  render() {
    const {imgSrc, index, crop} = this.state;
    const acceptedFileTypes = "image/x-png, image/png, image/jpg, image/jpg, image/jpeg";
    return (
      <div>
        {/*<Tabs value={index} fullWidth onChange={this.handleChange} style={styles.tabs}>
          <Tab label="Search" />
          <Tab label="Album choice" />
          <Tab label="Results" />
        </Tabs>*/}
        <SwipeableViews index={index} onChangeIndex={this.handleChangeIndex} disabled={true}>
          <div style={Object.assign({}, styles.slide, styles.slide1)}>
           <ImageDropCrop next={this.handleChangeIndex} 
            acceptedFileTypes={acceptedFileTypes} 
            handleDrop={this.handleDrop} 
            imgSrc={imgSrc}
            handleOnCropChange = {this.handleOnCropChange}
            imagePreviewCanevasRef = {this.imagePreviewCanevasRef}
            handleClearToDefault = {this.handleClearToDefault}
            handleOnCropComplete = {this.handleOnCropComplete}
            crop={crop} 
            onComplete={this.handleOnCropComplete}
            />
          </div>
          <div style={Object.assign({}, styles.slide, styles.slide2)}>
            <AlbumChoice choices={choices} onRadioButtonChange={this.onRadioButtonChange} back={this.backToPage1} selected={this.state.selected}/>
          </div>
          <div style={Object.assign({}, styles.slide, styles.slide3)}>
            <AlbumDisplay data={this.state.data} loading={this.state.loading} pageInfo={this.state.pageInfo} back={this.backToPage2} newSearch={this.handleClearToDefault}/>
          </div>
        </SwipeableViews>
      </div>
    );
  }
}

export default App;